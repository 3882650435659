
import { Component, Prop, Vue } from "vue-property-decorator";
import { appBaseDomain } from "@/env";
import { UFliterals } from "@/utils/literals";
import GroupsCreation from "@/components/sequence/GroupsCreation.vue";
import { ISequence } from "@/schemas/ISequence";
import SettingSelection from "@/components/sequence/SettingSelection.vue";

@Component({
  name: "RequestsPage",
  components: { SettingSelection, GroupsCreation },
})
export default class RequestsPage extends Vue {
  @Prop({ type: String, required: true })
  readonly code: string | undefined;

  private connected: {
    code: string;
    email: string;
    otp: string;
    username: string;
  }[] = [];
  /**
   * Boolean lock to disable timeout status request
   */
  private canRequest = true;
  private authorizedUsers: {
    code: string;
    email: string;
    otp: string;
    username: string;
  }[] = [];
  private dispatchedUsers: {
    code: string;
    email: string;
    otp: string;
    username: string;
  }[] = [];
  activitySelection = false;
  sequence: ISequence = {};
  enabledSettings: string[] = [];

  created(): void {
    this.fetchRequests();
    this.$store.dispatch("sequence/fetchFullByCode", {
      code: this.code,
      next: (sequence: ISequence) => {
        this.sequence = sequence;
      },
    });
  }

  /**
   * True if all connected users are selected to be dispatched in groups
   */
  get allChecked(): boolean {
    return this.connected.every((connectedUser) =>
      this.dispatchedUsers.includes(connectedUser)
    );
  }

  set allChecked(areAllChecked: boolean) {
    if (areAllChecked) {
      this.connected.forEach((connectedUser) => {
        if (
          !this.dispatchedUsers.find(
            (user) =>
              user.username == connectedUser.username ||
              user.code == connectedUser.code
          )
        ) {
          this.dispatchedUsers.push(connectedUser);
        }
      });
      return;
    }

    this.dispatchedUsers = this.dispatchedUsers.filter(
      (u) =>
        !this.connected.find(
          (user) => user.username == u.username || user.code == u.code
        )
    );
  }

  openSelectedSettings(): void {
    this.activitySelection = false;
  }

  fetchRequests(): void {
    if (this.canRequest) {
      this.canRequest = false;
      this.$store.dispatch("sequence/getConnectionRequests", {
        sequenceCode: this.code,
        next: (requests: string[]) => {
          this.canRequest = true;
          if (requests.length) {
            this.connected = [...requests].map((request: string) => {
              return JSON.parse(request);
            });
          }
        },
      });
    }
    this.$store.dispatch("sequence/getApprovedUsers", {
      sequenceCode: this.code,
      next: (approved: string[]) => {
        if (approved.length) {
          this.dispatchedUsers = [...approved].map((request: string) =>
            JSON.parse(request)
          );
        }
      },
    });
  }

  get link(): string {
    // return `${appBaseDomain}/join/${this.code?.toLowerCase()}`;
    return `${appBaseDomain}/join/${this.code}`;
  }

  deny(deniedUser: Record<string, any>): void {
    let deniedIndex = this.connected.findIndex(
      (user) => user.code === deniedUser.code
    );
    this.dispatchedUsers = this.dispatchedUsers.filter(
      (user) => user.email !== deniedUser.email
    );
    if (deniedIndex || deniedIndex === 0) {
      this.$store.dispatch("sequence/denyRequest", {
        denied: JSON.stringify(deniedUser),
        sequenceCode: this.code,
        next: () => {
          this.connected.splice(deniedIndex, 1);
        },
      });
    }
  }

  copyLink(): void {
    navigator.clipboard.writeText(this.link);
    this.$store.commit("SET_SNACK", {
      displaySnack: true,
      snackText: "Lien copié dans le presse-papier.",
      snackTimeOut: 1500,
    });
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  onCheckChange(
    isChecked: boolean,
    user: { code: string; email: string; otp: string; username: string }
  ): void {
    if (isChecked) {
      if (!this.dispatchedUsers.find((u) => u.email === user.email)) {
        this.dispatchedUsers.push(user);
      }
    } else {
      this.dispatchedUsers = this.dispatchedUsers.filter(
        (u) => u.email != user.email
      );
    }
  }
}
