
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import { ISequence } from "@/schemas/ISequence";
import { ISession } from "@/schemas/ISession";
import { IUserSessions } from "@/schemas/IUserSessions";
import { SessionState } from "@/schemas/Enums";
import clientCache from "@/utils/cacheUtils";
import { UFliterals } from "@/utils/literals";
import VueRouter, { NavigationFailure } from "vue-router";
import { IActivity } from "@/schemas/IActivity";

@Component({
  name: "" + "GroupsPage",
  components: {
    draggable,
  },
  mounted: function () {
    this.getUsersPerGroups();
  },
})
export default class GroupsPage extends Vue {
  @Prop({ type: String, required: true })
  readonly code: string | undefined;
  @Prop({ type: Function, required: true })
  removeUser!: CallableFunction | undefined;
  @Prop({ type: Function, required: true })
  settingsToOpenUnsetHandler!: CallableFunction;
  @Prop({ type: Array, required: true })
  readonly approved!: {
    code: string;
    email: string;
    otp: string;
    username: string;
  }[];
  // Current sequence
  private sequence: ISequence | undefined = undefined;
  // List of users approved for the current sequence
  // private approved: {
  //   username: string;
  //   code: string;
  //   id: string;
  // }[] = [];
  // List of user groups
  groups: { username: string; code: string; id: string }[][] = [];
  // Number of user required per groups
  usersPerGroups = undefined;
  private lastLength = 0;
  settingsToOpen: string[] = [];
  settingsToTransmit: string[] = [];

  @Watch("approved")
  approvedUpdate(
    value: {
      code: string;
      email: string;
      otp: string;
      username: string;
    }[]
  ): void {
    let alreadyDispatched = this.groups.flat();
    let newLength = value.length;
    // A user has been removed
    if (this.lastLength > newLength) {
      for (const groupsKey in this.groups) {
        Vue.set(
          this.groups,
          groupsKey,
          this.groups[groupsKey].filter((user) => this.approved.includes(user))
        );
      }
      this.groups = this.groups.filter((group) => group.length);
    }
    // A user has been added to the approved ones
    if (this.lastLength < newLength) {
      let added = this.approved.filter(
        (user) => !alreadyDispatched.includes(user)
      );
      if (added.length) {
        for (const index in this.groups) {
          while (
            this.groups[index].length < this.usersPerGroups &&
            added.length
          ) {
            let toAdd = added.shift();
            Vue.set(this.groups, index, [...this.groups[index], toAdd]);
          }
        }
        if (added.length) {
          let slice = Math.ceil(added.length / this.usersPerGroups);
          for (let i = 0; i < slice; i++) {
            this.groups.push([...added.splice(0, this.usersPerGroups)]);
          }
        }
      }
    }
    this.lastLength = value.length;
  }



  created(): void {
    this.lastLength = this.approved.length;
  }

  removeGroup(index: number): void {
    this.groups.splice(index, 1);
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  getUsersPerGroups(): void {
    this.$store.dispatch("sequence/fetchFullByCode", {
      code: this.code,
      next: (sequence: ISequence) => {
        this.$store.dispatch("activity/fetchById", {
          id: sequence.settings[0].activity.id,
          next: (activity: IActivity) => {
            this.usersPerGroups = activity.player_number;
          },
        });
      },
    });
  }

  /**
   * Generated random groups from the list of approved users
   */
  generateRandomGroups(): void {
    this.lastLength = this.approved.length;
    const users = [...this.approved];
    const nbGroups = Math.floor(users.length / this.usersPerGroups) + 1;
    this.groups = [];
    this.getUsersPerGroups();
    for (let i = 0; i < nbGroups; i++) {
      let group = [];
      for (let j = 0; j < this.usersPerGroups; j++) {
        if (users.length) {
          let rand = Math.floor(Math.random() * users.length);
          group.push(users[rand]);
          users.splice(rand, 1);
        }
      }
      if (group.length) this.groups.push(group);
    }
  }

  confirmGroups(): void {
    // Fetch a fresh instance of the current sequence
    this.$store.dispatch("sequence/fetchFullByCode", {
      code: this.code,
      next: (sequence: ISequence) => {
        this.sequence = sequence;
        // First, check that we know which settings to open
        this.settingsToOpen = JSON.parse(
          clientCache.get(`currentOpenedSettings:${this.sequence.id}`)
        );
        if (!this.settingsToOpen || !this.settingsToOpen?.length) {
          this.settingsToOpenUnsetHandler();
          return;
        }
        // Second, we check that there are at least one group
        // Then, we check that user have ids
        if (!this.sequence.is_open) {
          console.log("-------------------------");
          console.log("sequence NOT opened");
          console.log("-------------------------");
          this.$store.dispatch("sequence/approveRequests", {
            sequenceCode: this.code,
            next: () => {
              this.$store.dispatch("sequence/getApprovedUsers", {
                sequenceCode: this.code,
                next: (approved: string[]) => {
                  let approvedUsers = [...approved].map((request: string) =>
                    JSON.parse(request)
                  );
                  let groupsToAssign = [...this.groups].filter(
                    (group) => !!group.length
                  );
                  let settings = this.sequence.settings?.filter(
                    (sett) => !!this.settingsToOpen.find((s) => s === sett.id)
                  );
                  if (settings?.length && groupsToAssign.length) {
                    for (const setting of settings) {
                      for (const group of groupsToAssign) {
                        this.$store.dispatch("session/createSession", {
                          session: {
                            status: SessionState.Created,
                            setting_id: setting.id,
                            activity_id: setting.activity_id,
                          },
                          next: (createdSession: ISession) => {
                            let userSessions: IUserSessions[] = [];
                            let user_ids = group.map(
                              (user) =>
                                user.id ||
                                approvedUsers.find(
                                  (u) => u.username === user.username
                                ).id
                            );
                            console.log("-------------------------");
                            console.log("attribution des user a la session");
                            console.log(group);
                            console.log(user_ids);
                            console.log("-------------------------");
                            user_ids.forEach((user_id) => {
                              userSessions.push({
                                user_id,
                                sequence_id: sequence.id,
                              });
                            });
                            this.$store
                              .dispatch("session/addUserToSession", {
                                sessionId: createdSession.id,
                                userSessions,
                              })
                              .then(() => {
                                // this.settingsToTransmit.push(setting.id)
                                // console.log("push!")
                                // console.log(this.settingsToTransmit)
                                let now = new Date(Date.now());
                                now.setHours(now.getHours() + 2);
                                this.$store.dispatch("setting/updateSetting", {
                                  setting: { opened_at: now },
                                  id: setting.id,
                                });
                                clientCache.clear(
                                  `currentOpenedSettings:${this.sequence.id}`
                                );
                              });
                          },
                        });
                      }
                    }
                  }

                  if (this.sequence.id) {
                    const { isNavigationFailure, NavigationFailureType } =
                      VueRouter;
                    this.$router.push({
                      name: "sequence",
                      params: {
                        id: this.sequence.id,
                        toRefresh : true,
                        },
                      })
                      .catch((failure: NavigationFailure) => {
                        if (
                          !isNavigationFailure(
                            failure,
                            NavigationFailureType.deplicated
                          )
                        ) {
                          throw new Error(
                            `Router error ${failure.type} while navigating from ${failure.from} to ${failure.to}.`
                          );
                        }
                      });
                  } else {
                    this.$router.push({
                      name: "home",
                    });
                  }
                },
              });
            },
          });
        } else {
          this.$store.dispatch("sequence/approveRequests", {
            sequenceCode: this.code,
            next: (approvedUser) => {
              console.log("-------------------------");
              console.log("sequence OPEN");
              console.log("-------------------------");
              let groupsToAssign = [...this.groups].filter(
                (group) => !!group.length
              );
              let currentOpenedSettings: string[] = JSON.parse(
                clientCache.get(`currentOpenedSettings:${this.sequence.id}`)
              );
              let settings = this.sequence.settings?.filter(
                (sett) => !!currentOpenedSettings.find((s) => s === sett.id)
              );

              if (settings?.length && groupsToAssign.length) {
                for (const setting of settings) {
                  for (const group of groupsToAssign) {
                    this.$store.dispatch("session/createSession", {
                      session: {
                        status: SessionState.Created,
                        setting_id: setting.id,
                        activity_id: setting.activity_id,
                      },
                      next: (createdSession: ISession) => {
                        let userSessions: IUserSessions[] = [];
                        let user_ids = group.map(
                          (user) =>
                            user.id ||
                            approvedUser.find(
                              (u) => u.username === user.username
                            ).id
                        );
                        console.log("-------------------------");
                        console.log("attribution des user a la session");
                        console.log(group);
                        console.log(user_ids);
                        console.log("-------------------------");

                        user_ids.forEach((user_id) => {
                          userSessions.push({
                            user_id,
                            sequence_id: sequence.id,
                          });
                        });
                        console.log("-------------------------");
                        console.log("userSessions");
                        console.log(userSessions);
                        console.log("-------------------------");
                        this.$store
                          .dispatch("session/addUserToSession", {
                            sessionId: createdSession.id,
                            userSessions,
                          })
                          .then(() => {
                            let now = new Date(Date.now());
                            now.setHours(now.getHours() + 2);
                            this.$store.dispatch("setting/updateSetting", {
                              setting: { opened_at: now },
                              id: setting.id,
                            },
                            );
                          })
                          .then(() => {console.log("update done")});
                      },
                    });
                  }
                }
              }

              if (this.sequence.id) {
                this.$router.push({
                  name: "sequence",
                  params: {
                    id: this.sequence.id,
                    toRefresh : true,
                  },
                });
              } else {
                this.$router.push({
                  name: "home",
                });
              }
            },
          });
        }
      },
    });
  }

  addGroup(): void {
    this.groups.push([]);
  }

  removeUserHandler(user: {
    username: string;
    code: string;
    email: string;
  }): void {
    let groupIndex = this.groups.findIndex((group) => group.includes(user));
    let updated = this.groups[groupIndex];
    updated.splice(
      updated.findIndex((u) => u.email === user.email),
      1
    );
    Vue.set(this.groups, groupIndex, updated);
    this.lastLength -= 1;
    this.removeUser(user);
  }
}
